import React from "react"
import { HeroSection, SecondarySection } from "../../components/components"

const Press = () => {
  return (
    <>
      <HeroSection>Presse</HeroSection>
      <SecondarySection>Text</SecondarySection>
    </>
  )
}

export default Press
